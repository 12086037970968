.container {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
  .main {
    height: auto;
    // flex: 1;
    background-color: var(--primary);
    display: flex;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    // padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    .img_div {
      flex: 1;
      // width: 50%;
      // object-fit: cover;
      // background-image: url("https://images.pexels.com/photos/956981/milky-way-starry-sky-night-sky-star-956981.jpeg?auto=compress&cs=tinysrgb&w=800");
      background-size: "cover";
      background-color: #169e49;
      padding: 20px;
      .heading {
        .logo {
          height: 200px;
          width: 200px;
          object-fit: cover;
          flex: 1;
          // border-radius: 60%;
        }
        h1 {
          color: #fff;
          margin-bottom: 10px;
        }
        p {
          color: #fff;
        }
      }
    }
    .field_div {
      flex: 2;
      // max-width: 500px;
      object-fit: cover;
      padding: 30px;
      display: block;
      align-items: center;
      justify-content: center;

      .forgot_pass {
        display: flex;
        justify-content: end;
        margin-top: 20px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;
      }
      .button_div {
        display: flex;
        justify-content: center;
        margin-block: 20px;
      }
      .button {
        height: 35px;
        width: 90px;
      }
      .footer {
        font-size: 1rem;
        display: flex;
        align-self: center;
        align-items: center;
        gap: 10px;
        color: #000;
        .link_signup {
          font-size: 2rem;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .main {
      display: block;
    }
    .img_div {
      // flex: 1;
      // display: block;
      width: 100%;
      background-color: red;
      height: 10%;
    }
  }
}
