.regi_container {
  padding: 1.5rem;
  //   min-height: 100vh;
  width: 100vw;
  align-items: center;
  display: block;
  .regi_inner_container {
    width: 100%;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    flex: 1;
    border-radius: 0.5rem;

    .regi_header {
      font-size: larger;
      color: #000;
      font-weight: 600;
    }
    .otp_container {
      justify-content: center;
      display: flex;
      margin-block: 20px;

      .inputStyle {
        width: 2rem !important;
        height: 2rem;
        margin: 0 0.1rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}
