.container {
  display: flex;
  gap: 20px;

  .info_container {
    flex: 1;
    margin-top: 20px;
    gap: 10;
    span {
      font-size: 14;
      color: #000;
    }
    .textdiv {
      display: flex;
      align-items: center;
      gap: 10;
    }
    .input {
      background-color: #f3f3f3;
      padding: 10;
      margin-top: 10;
      border-radius: 5;
      // width: 90%;
    }

    .valuediv {
      display: flex;
      align-items: center;
      background-color: #f3f3f3;
      padding: 10px;
      border-radius: 5px;
      margin-top: 4px;
      flex: 1;
      p {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }
}

.input_container {
  display: flex;
  // align-items: center;
  // margin-top: 15px;
  gap: 20px;
  // flex: 1;
  .container {
    display: block;
    align-items: center;
    flex: 1;

    p {
      font-size: 16px;
      color: #000;
      margin-left: 10px;
      font-weight: 400;
      margin-top: 15px;
      margin-bottom: 4px;
    }
    .textarea_div {
      // margin-top: 5px;
      display: flex;
      flex: 1;
      .textarea {
        background-color: #fff;
        outline-style: none;
        border-style: none;
        padding: 7px;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(201, 201, 201);
        flex: 1;
        width: 100%;
        resize: vertical;
        border-radius: 5px;
        font-size: 16px;
      }
    }
    .input_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      // height: 40px;
    }
    .input_div {
      background-color: #fff;
      padding: 7px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      // margin-top: 5px;
      border-style: solid;
      border-width: 1px;
      border-color: rgb(201, 201, 201);
      flex: 1;

      .input {
        outline-style: none;
        border-style: none;
        background-color: transparent;
        flex: 1;
        font-size: 16px;
      }
    }
  }
}
.dropdown_container {
  display: block;
  align-items: center;
  flex: 1;
  // margin-top: 10px;
  width: 100%;
  p {
    font-size: 16px;
    color: #999;
    margin-left: 10px;
    font-weight: 500;
  }
  .dropdown {
    // flex: 1;
    display: flex;
    width: 100%;
  }
}

.button_style {
  gap: 6px;
  display: flex;
  align-items: center;
  text-decoration-line: none;
  max-width: max-content;
  // background-color: #0175c2;
  color: white;
  padding-inline: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  // text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  font-size: 14px;
  cursor: pointer;
  &:hover,
  &:active {
    // background-color: #0175c2;
    box-shadow: 0 0.5rem 0.5rem 0 #dedddd;
    // box-shadow: yellow;
    transform: scale(0.96);
    text-decoration-line: none;
    text-decoration-style: none;
  }
  .loader {
    margin-inline: 0.9rem;
  }
}

.docdiv {
  display: block;
  flex: 1;
  margin-top: 10px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .docList {
    display: flex;
    align-items: center;
    margin-inline: 2%;
    margin-block: 10px;
    .docfilename {
      font-size: 16px;
      color: #000;
      margin: 0;
      width: 10%;
    }
    .docname {
      margin: 0;
      flex: 1;
    }
    .icon {
      cursor: pointer;
    }
  }
  p {
    font-size: 16px;
    color: #000;
    margin-left: 10px;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 4px;
  }
  .nameinput {
    border-style: none;
    outline-style: none;
    flex: 1;
    font-size: 16px;
    color: #000;
    padding-inline: 15px;
    background-color: #fff;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(201, 201, 201);
    display: flex;
    margin-block: 10px;
    padding: 7px;
    width: 100%;
  }
  .buttondiv {
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    height: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(201, 201, 201);
    align-items: center;
    overflow: hidden;
    flex: 1;
    .file {
      flex: 1;
      padding-inline: 20px;
      margin: 0;
    }
    text {
      flex: 1;
      padding-inline: 15px;
    }
    .button {
      display: flex;
      border-style: none;
      padding: 10px;

      padding-inline: 15px;
      background-color: rgb(201, 201, 201);
      margin-bottom: 0;
    }

    input[type="file"] {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .container,
  .input_container {
    display: block;
  }
}
