.sidebar_wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  position: relative;
  background-color: rgba(249, 250, 251, 0.8);
  flex: 1;
  z-index: 0;
}
.sidebar_container {
  width: 13rem;
  height: 100%;

  background-color: #fff !important;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;

  display: block;
  z-index: 100;
  overflow-y: scroll;
}
.logo_div {
  height: 4rem;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  .logo {
    height: 50px;
    width: 80%;
    object-fit: contain;
  }
  h2 {
    color: #169e49;
    font-weight: 700;
  }
}
.items {
  display: block;
}

.main_container {
  width: calc(100% - 13rem);
  margin-left: 13rem;
  display: block;

  min-height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.header_container {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 13rem;
  // width: 100%;
  z-index: inherit;
}
.main {
  margin-top: 4rem;
  height: max-content;
  display: block;

  flex: 1;
  // padding: 20px;
  z-index: 0;
}
.footer_container {
  display: block;
  position: sticky;
  bottom: 0;
  z-index: inherit;
}

@media (max-width: 768px) {
  .sidebar_container {
    display: none;
    width: 0rem;
    height: 100%;
  }
  .main_container {
    width: 100%;
    margin-left: 0rem;
  }
  .header_container {
    left: 0;
  }
}
