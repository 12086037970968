*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid #d5d6d7;
  outline-style: none;
  border-style: none;
  text-decoration: none;
}
input {
  background-color: transparent;
}
.card {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%);
}
.r_border {
  border-right: 1px solid rgba(201, 201, 201, 0.47);
  margin-right: 20px;
}
.p20 {
  padding: 20px;
}
.p10 {
  padding: 10px;
}

.p5 {
  padding: 5px;
}
.br5 {
  border-radius: 5px;
}
.padding-xy {
  padding: 10px 15px;
}
.pv {
  padding-block: 10px;
}
.ph {
  padding-inline: 10px;
}
.ph20 {
  padding-inline: 20px;
}
.sb {
  justify-content: space-between;
}
.jcc {
  justify-content: center;
}
.jce {
  justify-content: end;
}
.alc {
  align-items: center;
}
.over_hidden {
  overflow: hidden;
}
.df {
  display: flex;
}
.db {
  display: block;
}
.gap10 {
  gap: 10px;
}
.gap5 {
  gap: 5px;
}
.gap20 {
  gap: 20px;
}
.flex {
  flex: 1;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.m20 {
  margin: 20px;
}
.mt10 {
  margin-top: 10px;
}
.mt5 {
  margin-top: 5px;
}
.mt20 {
  margin-top: 20px;
}
.mt15 {
  margin-top: 15px;
}
.br {
  border-radius: 5px;
}
.search-bar {
  flex: 0.5;
}
.h-12 {
  height: 3rem;
}
.cursor {
  cursor: pointer;
}
.heading-text {
  font-size: large;
  color: #000;
  font-weight: 700;
}
.title-text {
  font-size: larger;
  color: #000;
  font-weight: 600;
}
.medium-text {
  font-size: medium;
  color: #000;
  font-weight: 500;
}
.label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}
.input-text {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-color-black);
}
.placeholder-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color-light);
}
.text_bg {
  background-color: var(--text-color-bg);
  padding: 7px 15px;
  /* padding: 10px 15px; */
  border-radius: 5px;
}
.custom-input-style {
  background-color: #fff;
  padding: 7px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
  border: 0.5px solid rgb(201, 201, 201);
  flex: 1;
}
.input-df {
  display: flex;
}
@media screen and (max-width: 1080px) {
  .input-df {
    display: block;
  }
}
.input-border {
  border: 0.5px solid rgb(201, 201, 201);
  padding: 7px 15px;
  border-radius: 5px;
}
.input-area {
  resize: vertical;
}
.text-sm {
  font-size: 0.875rem;
}
.choose-button {
  background-color: rgb(201, 201, 201);
  display: flex;
  padding-inline: 15px;
  align-items: center;
  cursor: pointer;
}
.border {
  border: 0.5px solid rgb(201, 201, 201);
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}
.border-gray-200 {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity));
}
.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
}
.bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity));
}
.icons {
  margin-inline: 5px;
  cursor: pointer;
}
.link-br {
  border-right: 3px solid #169e49;
}
.activelink-bg {
  background-color: #169e4911;
  color: #169e49;
}
.unactivelink-bg {
  color: #000;
}
.font1 {
  font-size: 1rem;
  color: #000;
}
/* .link {
  padding-block: 10px;
  padding-inline: 15px;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
  // margin-top: 5px; 
} */
.link {
  font-size: 15px;
  font-weight: 400;
  gap: 10px;
  padding-block: 4px;
  padding-inline: 15px;
}
.link:hover {
  background-color: #169e4910;
}
.inputStyle {
  width: 2rem !important;
  height: 2rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.chart {
  display: block;
  flex: 1;
  height: 20rem;
  /* width: 100%; */
}

:root {
  --green-color: #169e49;
  --green-bg-color: #169e4966;
  --green-bg-hover-color: #169e4922;
  --text-color-light: #757575;
  --text-color-bg: #f3f3f3;
  --text-color-black: #000000;
  --text-color-white: #fff;
  --toastify-color-info: #3498db;
  --toastify-color-dark: #121212;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
}
.uppercase {
  text-transform: uppercase;
}

.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs14 {
  font-size: 14px;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw400 {
  font-weight: 400;
}
.pb6 {
  padding-bottom: 6px;
}
.black {
  color: #000;
}
.trash {
  color: #f00;
}
.yellow {
  color: 0xffe000;
}
.green {
  color: #169e49;
}
.orange {
  color: #f37022;
}
.light-green {
  background-color: #169e4999;
}
.light-green1 {
  background-color: #169e4922;
}

.view {
  color: #007bc9;
}
.gray {
  color: #757575;
}
.float-r {
  float: right;
}
.max_width {
  max-width: 60rem;
}
.asc {
  align-self: center;
}
.ass {
  align-self: flex-start;
}
.jcse {
  justify-content: space-evenly;
}

.p_link_unactive {
  display: flex;
  color: #000;
  justify-content: space-between;
  margin-top: 10px;

  padding-block: 10px;
  padding-left: 10px;
  gap: 10px;
}
.p_link_active {
  background-color: #169e4910;
}

.p_link_unactive:hover {
  background-color: #169e4910;
}
.avtar {
  height: 30px;
  width: 30px;
  display: flex;
  background-color: #169e49;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.show_p {
  display: flex;
}
.hide_p {
  display: none;
}
@media screen and (max-width: 400px) {
  .show_p {
    display: none;
  }
  .hide_p {
    display: flex;
  }
}

.head_df {
  display: flex;
}
@media screen and (max-width: 300px) {
  .head_df {
    display: block;
  }
}

.custom_input_df_button {
  display: flex;
}

.h2_heading {
  font-size: x-large;
  font-weight: 700;
  color: #000;
}

@media screen and (max-width: 400px) {
  .custom_input_df_button {
    display: block;
  }
  .h2_heading {
    font-size: large;
  }
}
.profile_df {
  display: flex;
}

.show_profile_options {
  display: flex;
}
.hide_profile_options {
  display: none;
}
@media (max-width: 768px) {
  .profile_df {
    display: block;
  }
  .r_border {
    border-right: 0px solid rgba(201, 201, 201, 0.47);
  }
  .show_profile_options {
    display: none;
  }
  .hide_profile_options {
    display: block;
  }
}

.footer_div {
  display: flex;
}
@media screen and (max-width: 600px) {
  .footer_div {
    display: block;
  }
}

.br-left-orange {
  border-left: 3px solid #f37022;
}
.br-left-blue {
  border-left: 3px solid #007bc9;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
.grid-container-order {
  display: grid;
  grid-template-columns: repeat(3, 20px [col-start]);
  /* grid-template-columns: 10rem 10rem 10rem; */
  grid-gap: 1rem;
}

/* .grid-item {
  background-color: #f2f2f2;
  padding: 20px;
  /* min-width: 500px; */
/* align-self: flex-start;  
} */

/* @media (max-width: 768px) {
  .grid-container-order {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .grid-container-order {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 380px) {
  .grid-container-order {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
} */

@media (max-width: 300px) {
  .grid-item {
    max-width: 100%;
  }
}

.inv_table,
.inv_tr,
.inv_td {
  border: 1px solid;
}
.inv_table {
  border-collapse: collapse;
  width: 100%;
  align-self: center;
  overflow-y: scroll;
}
.inv_tr,
.inv_td {
  text-align: center;
  padding: 8px;
}

.inv_th,
.inv_td {
  height: 40px;
}
