.container {
  height: 4rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  // border-bottom: 0.5px solid rgb(231, 228, 228);
  // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
  .left {
    display: none;
    align-items: center;
    gap: 20px;
    h1 {
      color: #0175c2;
    }
  }

  .items {
    display: flex;
    align-items: center;
    gap: 15px;

    .profilediv {
      display: flex;
      align-items: center;
      margin-inline: 20px;
      position: relative;
      background-color: "red";
      padding: 10;
      overflow: hidden;
      cursor: pointer;
      border-radius: 10px;
      p {
        color: black;
        margin-inline: 12px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    .counter {
      width: 15px;
      height: 15px;
      background-color: red;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
}
.logo {
  height: 4rem;
  border-bottom: 1px solid #999;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  h2 {
    color: #0175c2;
  }
}

.items {
  display: block;

  .activeLink {
    background-color: #0175c266;
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    border-right: 3px solid #0175c2;
    color: #0175c2;
    text-decoration-line: none;
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
    &:hover {
      text-decoration-style: none;
    }
  }
  .link {
    // background-color: #0175c266;
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    color: #000;
    text-decoration-line: none;
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
    &:hover {
      text-decoration-style: none;
    }
  }
}
.manage {
  display: flex;
  padding-left: 10px;
  gap: 10px;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  align-items: center;
}

.activeLink2 {
  background-color: #0175c266;
  display: flex;
  padding-block: 5px;
  padding-left: 30px;
  gap: 10px;
  align-items: center;
  border-right: 3px solid #0175c2;
  color: #0175c2;
  text-decoration-line: none;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
.link2 {
  // background-color: #0175c266;
  display: flex;
  padding-block: 5px;
  padding-left: 30px;
  gap: 10px;
  align-items: center;
  color: #000;
  text-decoration-line: none;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container {
    .left {
      display: flex;
      // width: auto;
      // overflow: visible;
    }
  }
}

.noti_wrapper {
  display: block;
  width: 320px;
  // gap: 20px;
  // padding: 20px;
  .notification_item {
    background-color: #fff;
    // box-shadow: 2px 4px 10px 2px rgba(201, 201, 201, 0.47);
    border-bottom: 0.1px solid #e2dfdf;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    &:hover {
      background-color: rgba(227, 227, 227, 0.47);
    }

    .row_div {
      display: flex;
      gap: 20px;
      align-items: center;
      // margin-top: 5px;
      .row {
        display: flex;
        gap: 10px;
      }
      .key {
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
      .value {
        color: #000;
        font-size: 11px;
        font-weight: 400;
      }
      .noti_type {
        background-color: #169e4933;
        color: #169e49;
        padding-inline: 10px;
        border-radius: 20px;
        font-size: 11px;
        --text-opacity: 1;
      }
    }

    .p_value {
      font-size: 14px;
      margin-bottom: 5px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // text-overflow: ellipsis;
      // color: #000;
      // font-size: 14;
      // font-weight: 500;
      // margin-bottom: 0px;
    }
  }
}
